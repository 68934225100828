*
.text-box h1{
    font-size:70px;
    line-height: 160px;
    margin-left: -10px;
color: transparent;
-webkit-text-stroke: 1px #fff;

background-clip: text;
background-position: 0 0 ;
animation: back 30s linear infinite;


}
@keyframes back {
    100%{
        background-position:2000px ;
    }
}